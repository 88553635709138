.bg-faded {
  background-color: #eafdf2;
  color: #6f6f6f;
}

.bg-important {
  background-color: #30e981;
}

.vocabularyOptions {
  border-left: 4px solid #faf9fc;
}



.activeBtn {
  background-color: #eafdf2;
  color: #3a9a64;
}

.inactiveBtn {
  color: #757575;
}

.btnOpenClose {
  background-color: #fff;
  border: 1px solid #f5f5f5;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
  padding: 0.5rem 1rem;
  border-radius: 30px;
  width: 150px;
  height: 50px;
  margin: 0 auto;
  font-weight: 700;
}

.songArt {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 10px;
}

.saveVocab:hover {
  background-color: #fff;
  border: 1px solid rgba(34, 34, 34, 0.452);
  border-radius: 10px;
  cursor: pointer;
}

.removeBtn {
  border: 2px solid #beb8b6;
  border-radius: 30px;
  padding: 4px;
  font-size: 14px;
  width: 100px;
  height: 30px;
  color: #3a9a64;
  cursor: pointer;
}

.removeBtn:hover {
  background-color: #fff;
  border: 2px solid #3a9a64;
  color: #3a9a64;
  cursor: pointer;
}

.vocabularyOptionsHeader {
  background-color: #eafdf2;
  max-width: 90%;
  margin: 0 auto;
  overflow: hidden;
  height: 200px;
}

.vocabOptionsCircle {
  position: absolute;
  top: -80px;
  right: -80px;
  width: 170px;
  height: 170px;
  background-color: #30e981;
  border-radius: 50%;
}

.searchBar::placeholder {
  position: absolute;
  color: #757575;
  right: 10px;
}

.addBtn,
.deleteBtn {
  background-color: #30e981;
  border-radius: 30px;
  padding: 0.5rem 1rem;
  color: #fff;
}

.deleteBtn {
  background-color: #dc3545;
  color: #fff;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.relatedWord {
  background-color: #eafdf2;
  border-radius: 30px;
  padding: 0.5rem 1rem;
  color: #3a9a64;
  margin: 0.5rem;
  font-weight: 700;
  text-align: center;
}

.cursorPointer {
  cursor: pointer;
}


.loginBtn {
  background-color: #f6f6f6;
}

.signUpBtn {
  background-color: #30e981;
  color: #fff;
}

.loginBtn,
.signUpBtn {
  border-radius: 30px;
  padding: 12px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landingImg {
  object-fit: cover;
  height: 50vh;
}

.circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #30e981;
}

.hoverDelete:hover {
  background-color: #fff;
  border: 2px solid #3a9a64;
  color: #3a9a64;
  cursor: pointer;
}

.tagBgActive {
  background-color: #eafdf2;
  color: #3a9a64;
  border: 1px solid #3a9a64;
}

.tagBgInactive {
  background-color: #f5f5f5;
  color: #757575;
}

.filterBtn {
  border-radius: 8px;
  padding: 0.5rem 1rem;
  color: #222;
  background-color: #f5f5f5;
  border: none;
  margin: 0.5rem;
  width:100px;
}

.filterBtn:hover {
  background-color: #eafdf2;
  color: #3a9a64;
  cursor: pointer;
}

.tagCount {
  background-color: #30e981;
  border-radius: 50%;
  padding: 0.2rem;
  color: #fff;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

}


.dropDown {
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  width: 100% !important;
  margin-top: 50px;
}

.signUpForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nextBtn {
  background-color: #30e981;
  padding: 0.5rem 1rem;
  color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  margin-top: 1rem;
  width: 100%;
  font-weight: 600 !important;
}

.backBtn {
  background-color: #fff;
  padding: 0.5rem 1rem;
  color: #222;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  margin-top: 1rem;
  width: 100%;
  font-weight: 600 !important;
  border: 1px solid #e0e0e0;
}

.inputField {
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 0.5rem 1rem;
  width: 100%;
  margin-top: 1rem;
}

@media screen and (max-width: 768px) {
  .landingButtonResized {
    width: 100% !important;
    height: 45px;
  }
}
  

